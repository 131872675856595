import cartReducer from "./cartReducer";
import { createRoot } from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { PersistGate } from "redux-persist/integration/react";

// import App from "./App";
// import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, cartReducer);

export const store = configureStore({
  reducer: {
    cart: persistedReducer,
  },
  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Middleware configuration for the store.
   *
   * The `serializableCheck` middleware is used to ensure that only serializable
   * data is stored in the store. The `ignoredActions` option is used to ignore
   * the actions that are generated by the `redux-persist` library, which are
   * not serializable.
   *
   * See https://redux-toolkit.js.org/rtk-query/usage/serializing-actions
   * for more information.
   */
  /******  0c0f80b6-9ff0-433a-924c-c0998b5a6de7  *******/
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
});

export let persistor = persistStore(store);
