import React from "react";
import "./Products.scss";
import { useParams } from "react-router-dom";
import List from "../../components/List/List";
import { useState } from "react";
import useFetch from "../../hooks/useFetch";
const Products = () => {
  const catId = parseInt(useParams().id);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [minPrice, setMinPrice] = useState(0);
  const [sort, setSort] = useState("null");
  const [selectedSubCats, setSelectedSubCats] = useState([]);
  const { data, loading, error } = useFetch(process.env.REACT_APP_API_URL + `/sub-categories?[filters][categories][id][$eq]=${catId}`);
  // const { data, loading, error } = useFetch(import.meta.env.VITE_APP_API_URL + `/sub-categories?[filters][categories][id][$eq]=2`);
  console.log("data", data);
  console.log("catId", catId);

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    setSelectedSubCats(isChecked ? [...selectedSubCats, value] : selectedSubCats.filter((item) => item !== value));
  };

  console.log("selectedCats", selectedSubCats);

  return (
    <div className="products">
      <div className="left">
        <div className="filterItem">
          <h2>Product Categories</h2>
          {data?.map((item) => (
            <div className="inputItem" key={item.id}>
              <input type="checkbox" id={item.id} value={item.id} onChange={handleChange} />
              <label htmlFor="2">{item.attributes.title}</label>
              {/* <label htmlFor={item.id}>{item.attributes.title}</label> */}
            </div>
          ))}
          {/* <div className="inputItem">
            <input type="checkbox" id="1" value="1" />
            <label htmlFor="1">Shoes</label>
          </div>
          <div className="inputItem">
            <input type="checkbox" id="2" value="2" />
            <label htmlFor="2">Skirts</label>
          </div>
          <div className="inputItem">
            <input type="checkbox" id="3" value="3" />
            <label htmlFor="3">Coats</label>
          </div> */}
        </div>
        <div className="filterItem">
          <h2>Filter by Price</h2>
          <div className="inputItem">
            <span>{minPrice}</span>
            {/* <input type="range" min={10} max={1000} value={minPrice} onChange={(e) => setMinPrice(e.target.value)} /> */}
            <input type="range" min={10} max={1000} value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
            <span>{maxPrice}</span>
          </div>
        </div>
        <div className="filterItem">
          <h2>Sort by</h2>
          <div className="inputItem">
            <input type="radio" id="asc" value="asc" name="price" onChange={(e) => setSort(e.target.value)} />
            <label htmlFor="asc">Price (Lowest first)</label>
          </div>
          <div className="inputItem">
            <input type="radio" id="desc" value="desc" name="price" onChange={(e) => setSort(e.target.value)} />
            <label htmlFor="desc">Price (Highest first)</label>
          </div>
        </div>
      </div>
      <div className="right">
        <img className="catImg" src="https://images.pexels.com/photos/1074535/pexels-photo-1074535.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
        <List catId={catId} maxPrice={maxPrice} minPrice={minPrice} sort={sort} subCats={selectedSubCats} />
      </div>
    </div>
  );
};

export default Products;
