// import { configureStore } from "@reduxjs/toolkit";
// import cartReducer from "./cartReducer";

// export default configureStore({
//   reducer: {
//     cart: cartReducer,
//   },
// });

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // value: 0,
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = state.products.find((product) => product.id === action.payload.id);
      if (product) {
        // product.quantity += 1;
        product.quantity += action.payload.quantity;
      } else {
        // state.products.push({ ...action.payload, quantity: 1 });
        state.products.push(action.payload);
      }
    },
    removeItem: (state, action) => {
      state.products = state.products.filter((product) => product.id !== action.payload);
    },
    incrementQuantity: (state, action) => {
      const product = state.products.find((product) => product.id === action.payload);
      if (product) {
        product.quantity += 1;
      }
    },
    decrementQuantity: (state, action) => {
      const product = state.products.find((product) => product.id === action.payload);
      if (product && product.quantity > 1) {
        product.quantity -= 1;
      }
    },
    resetCart: (state) => {
      state.products = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeItem, incrementQuantity, decrementQuantity, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
