import React from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector, useDispatch } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
// import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { makeRequest } from "../../makeRequest";
const Cart = () => {
  // const data = [
  //   {
  //     id: 1,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     desc: "long sleeve graphic t-shirt",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  //   {
  //     id: 2,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     desc: "Coat",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  // ];
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity * item.price;
      return total.toFixed(2);
    });
    return total;
  };

  // const handlePayment = async () => {
  //   try {
  //     const response = await axios.post("http://localhost:1337/api/orders", {
  //       products,
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const stripePromise = loadStripe("pk_test_51Oki9WHRisC4fggGUH2NTqkKtsdMimQ523joVfQbSmN2FhtXRj3nN1FLHUI5dKqzvj9DH3OO5VUMwUMjRIoSDYxO00RRf9hYK0");
  // const stripePromise = loadStripe("process.env.STRIPE_PUBLIC_KEY");
  console.log(process.env.STRIPE_PUBLIC_KEY);
  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post("/orders", {
        products,
        email: "test@test.com",
        name: "test",
        address: "test",
        city: "test",
        state: "test",
        zip: "test",
        country: "test",
      });
      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSessionId.id,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="cart">
      <h1>Products in your cart</h1>
      {products.map((item) => (
        <div className="item" key={item.id}>
          <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
          <div className="details">
            <h1>{item.title}</h1>
            <p>{item.desc?.substring(0, 100)}</p>
            <div className="price">
              {item.quantity} x${item.price}
            </div>
            {/* <div className="price">{item.isNew ? <h2>${item.price}</h2> : <h2>${item.oldPrice}</h2>}</div> */}
          </div>
          <DeleteOutlinedIcon className="delete" onClick={() => dispatch(removeItem(item.id))} />
        </div>
      ))}
      <div className="total">
        <span>SUBTOTAL</span>
        <span>${totalPrice()}</span>
      </div>
      <button onClick={handlePayment}>PROCEED TO CHECKOUT</button>
      <span className="reset" onClick={() => dispatch(resetCart())}>
        Reset Cart
      </span>
    </div>
  );
};

export default Cart;
