// import React, { useState, useEffect } from "react";
import React from "react";
import "./FeatureProducts.scss";
import Card from "../Card/Card";
// import axios from "axios";
import useFetch from "../../hooks/useFetch";
// import { useState } from "react";
import { useState } from "react";

const FeatureProducts = ({ type }) => {
  // const data = [
  //   {
  //     id: 1,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  //   {
  //     id: 2,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  //   {
  //     id: 3,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  //   {
  //     id: 4,
  //     img: "https://images.pexels.com/photos/2065200/pexels-photo-2065200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     img2: "https://images.pexels.com/photos/2703202/pexels-photo-2703202.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //     title: "featured",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //   },
  // ];

  const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const products = await axios.get(import.meta.env.VITE_APP_API_URL + `/products?populate=*&[filters][type][$eq]=${type}`, {
  //         headers: {
  //           Authorization: `Bearer ${import.meta.env.VITE_APP_API_TOKEN}`,
  //         },
  //       });
  //       setProducts(products.data.data);
  //       console.log(products.data.data);
  //       console.log(products.data);
  //       console.log(products);
  //       console.log(import.meta.env.VITE_APP_API_URL);
  //       console.log(import.meta.env.VITE_APP_API_TOKEN);
  //       console.log(products.data.attributes.image);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const { data, loading, error } = useFetch(`/products?populate=*&[filters][type][$eq]=${type}`);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await axios.get(process.env.REACT_APP_API_URL + "/products?populate=*", {
  //         headers: {
  //           Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  //         },
  //       });
  //       setProducts(data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <div className="FeatureProducts">
      <div className="top">
        <h1>{type} Products</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis ducimus eligendi, recusandae consectetur sapiente esse? Vitae quos recusandae aut eaque quas porro sit dolor, soluta expedita officiis deserunt suscipit aperiam!</p>
      </div>
      <div className="bottom">{error ? "Something went wrong!" : loading ? "Loading..." : data?.map((item) => <Card item={item} key={item.id} />)}</div>
    </div>
  );
};

export default FeatureProducts;
